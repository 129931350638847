import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: './dashboards/dashboard.module#DashboardModule' },
      { path: 'starter', loadChildren: './starter/starter.module#StarterModule' },
      { path: 'component', loadChildren: './component/component.module#ComponentsModule' },
      { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
      { path: 'forms', loadChildren: './form/forms.module#FormModule' },
      { path: 'tables', loadChildren: './table/tables.module#TablesModule' },
      { path: 'charts', loadChildren: './charts/charts.module#ChartModule' },
      { path: 'add-patients', loadChildren: './add-patients/add-patients.module#addPatientsModule' },
      { path: 'add-patient', loadChildren: './add-patient/add-patient.module#AddPatientModule' },


      { path: 'add-organizations', loadChildren: './add-organizations/add-organizations.module#addOrganizationsModule' },
      { path: 'add-org', loadChildren: './add-org/add-org.module#AddOrgModule' },
      { path: 'add-clinics', loadChildren: './add-clinics/add-clinics.module#AddClinicsModule' },
      { path: 'add-doctors', loadChildren: './add-doctors/add-doctors.module#AddDoctorsModule' },
      { path: 'care-coordinatorsentry', loadChildren: './care-coordinatorsentry/care-coordinatorsentry.module#CareCoordinatorsentryModule' },
      { path: 'add-diets', loadChildren: './add-diets/add-diets.module#AddDietsModule' },
      { path: 'add-wounds', loadChildren: './add-wounds/add-wounds.module#AddWoundsModule' },
      { path: 'add-physical', loadChildren: './add-physical/add-physical.module#AddPhysicalModule' },
      { path: 'add-vitals', loadChildren: './add-vitals/add-vitals.module#AddVitalsModule' },
      { path: 'add-videos', loadChildren: './add-videos/add-videos.module#AddVideosModule' },
      { path: 'add-medicationlist', loadChildren: './add-medicationlist/add-medicationlist.module#AddMedicationlistModule' },
      { path: 'createcategory', loadChildren: './createcategory/createcategory.module#CreatecategoryModule' },

      { path: 'addappointment/:id', loadChildren: './addappointment/addappointment.module#AddappointmentModule' },
      { path: 'add-reports', loadChildren: './add-reports/add-reports.module#AddReportsModule' },
      { path: 'add-diagnosis', loadChildren: './add-diagnosis/add-diagnosis.module#AddDiagnosisModule' },
      { path: 'add-FAQ', loadChildren: './add-faq/add-faq.module#AddFaqModule' },
      { path: 'add-defaults', loadChildren: './add-defaults/add-defaults.module#AddDefaultsModule' },

      { path: 'details/:id', loadChildren: './details/details.module#DetailsModule' },
      { path: 'hospitaldetails/:id', loadChildren: './hospitaldetails/hospitaldetails.module#HospitaldetailsModule' },
      { path: 'clinicsdetails/:id', loadChildren: './clinicsdetails/clinicsdetails.module#ClinicsdetailsModule' },
      { path: 'doctorsdetails/:id', loadChildren: './doctorsdetails/doctorsdetails.module#DoctorsdetailsModule' },
      { path: 'care-coordinatorsdetails/:id', loadChildren: './care-coordinatorsdetails/care-coordinatorsdetails.module#CareCoordinatorsdetailsModule' },
      { path: 'dietsdetails/:id', loadChildren: './dietsdetails/dietsdetails.module#DietsdetailsModule' },
      { path: 'woundsdetails/:id', loadChildren: './woundsdetails/woundsdetails.module#WoundsdetailsModule' },
      { path: 'physicaldetails/:id', loadChildren: './physicaldetails/physicaldetails.module#PhysicaldetailsModule' },
      { path: 'vitalsdetails/:id', loadChildren: './vitalsdetails/vitalsdetails.module#VitalsdetailsModule' },
      { path: 'videosdetails/:id', loadChildren: './videosdetails/videosdetails.module#VideosdetailsModule' },
      { path: 'medicationlistdetails/:id', loadChildren: './medicationlistdetails/medicationlistdetails.module#MedicationlistdetailsModule' },
      { path: 'patientdata/:type/:id', loadChildren: './patientdata/patientdata.module#PatientdataModule' },
      { path: 'reportsdetails/:id', loadChildren: './reportsdetails/reportsdetails.module#ReportsdetailsModule' },

      { path: 'reportsedit/:id', loadChildren: './reportsedit/reportsedit.module#ReportseditModule' },
      
      { path: 'diagnosisdetails/:id', loadChildren: './diagnosisdetails/diagnosisdetails.module#DiagnosisdetailsModule' },
      { path: 'FAQdetails/:id', loadChildren: './faq-edit/faq-edit.module#FaqEditModule' },
      { path: 'unreconciledDetails/:id', loadChildren: './unreconciled-details/unreconciled-details.module#UnreconciledDetailsModule' },
      { path: 'defaults/:id', loadChildren: './defaults-edit/defaults-edit.module#DefaultsEditModule' },

      { path: 'hospital-organizations', loadChildren: './hospital-organizations/hospital-organizations.module#hospitalOrganizationsModule' },
      { path: 'hospital-org-admin', loadChildren: './hospital-org-admin/hospital-org-admin.module#HospitalOrgAdminModule' },
      { path: 'clinics', loadChildren: './clinics/clinics.module#ClinicsModule' },
      { path: 'doctors', loadChildren: './doctors/doctors.module#DoctorsModule' },
      { path: 'care-coordinators', loadChildren: './care-coordinators/care-coordinators.module#CareCoordinatorsModule' },
      { path: 'diets', loadChildren: './diets/diets.module#DietsModule' },
      { path: 'wound-care', loadChildren: './wound-care/wound-care.module#WoundCareModule' },
      { path: 'physical', loadChildren: './physical/physical.module#PhysicalModule' },
      { path: 'vitals', loadChildren: './vitals/vitals.module#VitalsModule' },
      { path: 'videos', loadChildren: './videos/videos.module#VideosModule' },
      { path: 'medicationlist', loadChildren: './medicationlist/medicationlist.module#MedicationlistModule' },
      { path: 'notificationlogs', loadChildren: './notificationlogs/notificationlogs.module#NotificationlogsModule' },
      { path: 'accesslogs', loadChildren: './accesslogs/accesslogs.module#AccesslogsModule' },
      { path: 'reports', loadChildren: './reports/reports.module#ReportsModule' },
      { path: 'unreconciled', loadChildren: './unreconciled/unreconciled.module#UnreconciledModule' },
      { path: 'diagnosis', loadChildren: './diagnosis/diagnosis.module#DiagnosisModule' },
      { path: 'red-flag', loadChildren: './red-flag/red-flag.module#RedFlagModule' },
      { path: 'FAQ', loadChildren: './faq/faq.module#FaqModule' },
      { path: 'frequency', loadChildren: './frequency/frequency.module#FrequencyModule' },
      { path: 'defaults', loadChildren: './defaults/defaults.module#DefaultsModule' },
      { path: 'wound-care-static', loadChildren: './wound-care-static/wound-care-static.module#WoundCareStaticModule' },
      { path: 'extra-component', loadChildren: './extra-component/extra-component.module#ExtraComponentModule' },
      { path: 'apps', loadChildren: './apps/apps.module#AppsModule' },
      { path: 'sample-pages', loadChildren: './sample-pages/sample-pages.module#SamplePagesModule' },
      { path: 'add-wounds-static', loadChildren: './add-wounds-static/add-wounds-static.module#AddWoundsStaticModule' },
      { path: 'woundsdetailsstatic/:id', loadChildren: './woundsdetailsstatic/woundsdetailsstatic.module#WoundsdetailsstaticModule' },
      { path: 'userinteractions', loadChildren: './userinteractions/userinteractions.module#UserinteractionsModule' },
      { path: 'imagefrequencydescription/:id', loadChildren: './imagefrequencydescription/imagefrequencydescription.module#ImagefrequencydescriptionModule' },
      { path: 'help', loadChildren: './help/help.module#HelpModule' },
      { path: 'add-help', loadChildren: './add-help/add-help.module#AddHelpModule' },
      { path: 'help-edit/:id', loadChildren: './help-edit/help-edit.module#HelpEditModule' },
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },

  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
