import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';


@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {

  constructor(
    private http: HttpClient,)
     {
}


get(url: string): Observable<any> {
  return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      this.http
          .get(url, {
              headers: this.getHeaders(),
              responseType: 'blob'
          })
          .subscribe(m => {
              objectUrl = URL.createObjectURL(m);
              observer.next(objectUrl);
          });

      return () => {
          if (objectUrl) {
              URL.revokeObjectURL(objectUrl);
              objectUrl = null;
          }
      };
  });
}

getHeaders(): HttpHeaders {
  let headers = new HttpHeaders();

  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  ////let token = this.authService.getCurrentToken();
 // let token = { access_token: 'ABCDEF' }; // Get this from your auth service.
  let token= currentUser.access_token;
  if (token) {
      headers.set('Authorization', 'Bearer ' + token);
  }

  return headers;
}
}
