import { Component, OnInit, Input,Output, EventEmitter, ViewChild} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'button-view',
  template: `
    <button (click)="onClick()">View</button>
    
    <div class="video"  *ngIf="showVideo">
    <iframe width="300" 
    height="200" [src]="photoURL()" frameborder="0" allow="accelerometer; 
    autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>


   
    
</div>
  `,
  styleUrls: ['./customrender.component.css']
})
export class CustomrenderComponent implements ViewCell, OnInit {
  public renderValue;

  public showVideo:boolean = false;
 
  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.rowData.videoUrl);
  }
constructor(
  private sanitizer: DomSanitizer
){}
  @Input()
  public value: string;

  @Input()
  rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();
  
  @ViewChild('videoPlayer') videoplayer: any;

toggleVideo(event: any) {
    this.videoplayer.play();
}

  ngOnInit() {
    
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {

    this.showVideo = !this.showVideo;
    this.save.emit(this.rowData);

    
    
  }

 
  

 
}
