import { Component, OnInit, Input,Output, EventEmitter  } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
@Component({
  template: `
    <a (click)="onClick()"
    href="javascript:void(0);">{{ renderValue }}</a>
  `,
})
export class ReportsrenderidComponent implements ViewCell,OnInit {
  public renderValue;

  @Input()
  public value: string;

  @Input()
  rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();

  }
  constructor(private router: Router,) { }

  onClick() {
  this.router.navigate([`/reportsdetails/${this.rowData.report.id}`]);
    this.save.emit(this.rowData);
  }

}
