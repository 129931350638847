import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
@Component({

  template: `
    <a (click)="onClick()"
    href="javascript:void(0);">{{ renderValue }}</a>


  `,
})
export class UnreconciledRendedComponent implements OnInit {


  public renderValue;

  @Input()
  public value: string;

  @Input()
  rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = 'Review';


  }
  constructor(private router: Router,) { }



  onClick() {

  this.router.navigate([`/unreconciledDetails/${this.rowData.patientId}`]);

    this.save.emit(this.rowData);
  }

}
