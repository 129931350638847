import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {Router} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HospitalService } from '../../hospital.service';
import { Hospital } from '../../hospital';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
declare var $: any;
const currentUser = JSON.parse(localStorage.getItem('currentUser'));
// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'Authorization': `bearer ${currentUser.access_token}`,
//   }),
// };
const apiUrl = environment.ApiURL;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})

export class NavigationComponent implements OnInit {
  username=""
  image=''
  email=''
  currentUser
  data1
  data:any
  httpOptions
  isLoadingResults = true;
  @Output() toggleSidebar = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter();
  public mySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal, private router: Router,
              public authService: AuthService,
              private alertService: ToastrService,
              private http: HttpClient){
                                        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                        this.httpOptions = {
                                        headers: new HttpHeaders({
                                        'Content-Type': 'application/json',
                                        'Authorization': `bearer ${this.currentUser.access_token}`,
                                         }),
                                      }
     this.http.get(`${apiUrl}`+`users/me`,this.httpOptions)
     .subscribe(res => {
       this.data1 = res;
       this.data = this.data1.data
       this.username=this.data.displayName
       this.image=this.data.imageUrl
       this.email=this.data.email
     });
}
public showSearch = false;
ngOnInit(){}
  filterForeCasts(event) {

    console.log("event: " , event)

    if(event == 'Signout') {
      this.logout();
    }
    else if(event == 'Hospital-Org'){
      this.router.navigate(['/hospital-organizations']);
    }
    else if(event == 'Hospital-Org-admin'){
      this.router.navigate(['/hospital-org-admin']);
    }
    else if(event == 'Clinics'){
      this.router.navigate(['/clinics']);
    }
    else if(event == 'Doctors'){
      this.router.navigate(['/doctors']);
    }
    else if(event == 'Care-Coordinators'){
      this.router.navigate(['/care-coordinators']);
    }
    else if(event == 'Diets'){
      this.router.navigate(['/diets']);
    }

    else if(event == 'Wound-Care'){
      this.router.navigate(['/wound-care']);
    }
    else if(event == 'Physical-Activities'){
      this.router.navigate(['/physical']);
    }

    else if(event == 'Vitals'){
      this.router.navigate(['/vitals']);
    }
    else if(event == 'Videos'){
      this.router.navigate(['/videos']);
    }

    else if(event == 'Medications'){
      this.router.navigate(['/medicationlist']);
    } else if(event == 'notification-logs'){
      this.router.navigate(['/notificationlogs']);
    }
    else if(event == 'Access-logs'){
      this.router.navigate(['/accesslogs']);
    }

    else if(event == 'Reports'){
      this.router.navigate(['/reports']);
    }
    else if(event == 'Diagnosis'){
      this.router.navigate(['/diagnosis']);
    }
    else if(event == 'Diagnostics-flag'){
      this.router.navigate(['/red-flag']);
    }else if(event == 'FAQ'){
      this.router.navigate(['/FAQ']);
    }else if(event == 'defaults'){
      this.router.navigate(['/defaults']);

    }else if(event == 'Frequency'){
      this.router.navigate(['/frequency']);

    }else if(event == 'Wound-Care-Static'){
      this.router.navigate(['/wound-care-static']);

    }else if(event == 'userinteractions'){
      this.router.navigate(['/userinteractions']);

    }else if(event == 'Help'){
      this.router.navigate(['/help']);
    }
    else {
      this.alertService.error('Not Found');
    }
  }
  viewProfile() {
    this.router.navigate(['/dashboard']);
  }
   /*-------Logout Function--------------*/
   logout() {
    this.authService.logout();
    // window.location.reload();
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
  }
}

export class sets {
  static recordStatus: number;
  static page: number;
  static size: number;
  static sort: string;
  static patientId:string;
  static id:number= 2;
}
