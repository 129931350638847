import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpHeaders, HttpParams} from '@angular/common/http';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

const apiUrl = environment.ApiURL;
@Injectable({
    providedIn: 'root'
})

export class AuthService {
    url = environment.ApiURL;
    headers = new HttpHeaders({
        'Content-Type': 'application/text'
    });

    constructor(public http: HttpClient) {}
    /*===========LOGIN===========*/
    login(data) {
      var payload = JSON.stringify(data);
      var body = new FormData();
      body.append("u", data.u);
      body.append("p", data.p);
      body.append("client_id", data.client_id);
      body.append("grant_type", data.grant_type);
      return this.http.post(this.url + 'oauth/token', body);
      // return this.http.post<any>(this.url + 'oauth/token', data);
    }
    old
    oldpassword(password){
      this.old=password
    }
    oldpasswordlogin(){
      return this.old
    }
/*---------Change Password--------------*/
    changePassword(data){
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `bearer ${this.currentUser.access_token}`,
        }),
      }
      this.http.post(`${apiUrl}`+`users/me/password/change`,data,this.httpOptions)
      .subscribe(res => {
      }, (err) => {
      });
    }
/*---------Login check Password------------*/
    currentUser
    httpOptions
    data1
    data:any
    shouldChangePassword:boolean
    loginCheck(){
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `bearer ${this.currentUser.access_token}`,
        }),
      }
      const newPromise = new Promise((resolve, reject) => {
        this.http.get(`${apiUrl}`+`users/me`,this.httpOptions)
        .subscribe(res => {
          this.data1 = res;
          this.data = this.data1.data
          this.shouldChangePassword=this.data.shouldChangePassword
          resolve(this.shouldChangePassword);
          // if(this.shouldChangePassword== true)
          // {
          //   return true
          // } else{
          //   return false
          // }
        })
      });
      return newPromise;
    }
    /*===========LOGOUT===========*/
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.clear();
    }

}
