import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
 import {Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(private authenticationService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: ToastrService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
              this.router.navigate(['/dashboard'])
              window.location.reload();
            }
            if (err.status === 500) {
               this.alertService.error('Wrong User Name or Password');
            }
            if(err.status === 404) {
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}
