import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { AuthService } from '../../services/auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var $: any;
const apiUrl = environment.ApiURL;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  currentUser
  httpOptions
  data1
  data:any
  username
  image
  constructor(private modalService: NgbModal,
              private router: Router,
              private route: ActivatedRoute,
              public authService: AuthService,
              private http: HttpClient) {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `bearer ${this.currentUser.access_token}`,

  }),
}


this.http.get(`${apiUrl}`+`users/me`,this.httpOptions)
.subscribe(res => {
  this.data1 = res;
  this.data = this.data1.data
  this.username=this.data.displayName
  this.image=this.data.imageUrl
});
}
  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }
}
