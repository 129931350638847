import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
import { environment } from '../../environments/environment';

@Component({

  template: `
  <img [src]="url + 'image?image_name=1544502819183-Screenshot_from_2018-12-10_13-12-49.png'|secure",
  alt="user" width="36px" />abc


  `,
})
export class ImagerenderComponent implements ViewCell,OnInit {
  public renderValue;

  url = environment.ApiURL;



  @Input()
  public value: string;

  @Input()
  rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  //  this.renderValue = this.value.toString().toUpperCase();

  }

}
