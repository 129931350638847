import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    
  template: `

  <select type="text" class="form-control"   >
  <option *ngFor="let i of statuss" >{{this.rowData.id}}</option>
</select><br><br>

  `,
  styleUrls: ['./clinicrender.component.css']
})
export class ClinicrenderComponent implements OnInit {
  public renderValue;
  data: any

  isLoadingResults = true;
  clinics:Array<any> = [];
  statuss=[
    {
      id:0,
      name:'Select'
    },
    {
    id:1,
    name:'Active'
  },{
    id:2,
    name:'Inactive'
  }]
 
  

  @Input()
  public value: string;

  @Input()
  rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  
  
  constructor() {

   }

  ngOnInit() {




  }

}
