import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '/add-patient',
    title: 'Add Patients',
    icon: 'mdi mdi-account-plus',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/charts/chartjs',
     title: 'Analytics',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
   },
 
 
]