// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// http://dev-lb-891765181.ap-southeast-1.elb.amazonaws.com:8081/api/
//http://mobimd.surgery.wisc.edu/api/'
//https://mobimd.surgery.wisc.edu/api/

export const environment = {
  production: false,
  dataServiceURL: 'https://jsonplaceholder.typicode.com',
  ApiURL: 'https://mobimd.surgery.wisc.edu/api/'
};
