import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { ChartsModule } from 'ng2-charts'

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { ToastrModule } from 'ngx-toastr';

import { JwtInterceptor } from './core/jwt.interceptor.service';
import { InterceptorService } from './core/interceptor.service';
import { CustomEditComponent } from './custom-edit/custom-edit.component';
import { CustomrenderComponent } from './customrender/customrender.component';
import { NamerenderComponent}   from './namerender/namerender.component';
import { MedicationrenderComponent } from './medicationrender/medicationrender.component';
import { PatientrenderComponent } from './patientrender/patientrender.component';
import {TabsModule} from 'ngx-tabset';
import { CompliancerenderComponent } from './compliancerender/compliancerender.component';
import { VitalsrenderComponent } from './vitalsrender/vitalsrender.component';
import { ReportsrenderComponent } from './reportsrender/reportsrender.component';
import { ReportsrenderidComponent } from './reportsrenderid/reportsrenderid.component';
import { SecurePipe } from './secure.pipe';
import { ImagerenderComponent } from './imagerender/imagerender.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { ClinicrenderComponent } from './clinicrender/clinicrender.component';
import { DiagnosisrenderComponent } from './diagnosisrender/diagnosisrender.component';
import { FaqComponent } from './faq/faq.component';
import { FrequencyComponent } from './frequency/frequency.component';
import { UnreconciledRendedComponent } from './unreconciled-rended/unreconciled-rended.component';
import { ReportseditComponent } from './reportsedit/reportsedit.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [AppComponent, SpinnerComponent, FullComponent, BlankComponent,NamerenderComponent, NavigationComponent, BreadcrumbComponent, SidebarComponent, CustomEditComponent, CustomrenderComponent, MedicationrenderComponent, PatientrenderComponent, CompliancerenderComponent, VitalsrenderComponent, ReportsrenderComponent, ReportsrenderidComponent, SecurePipe, ImagerenderComponent, SanitizeHtmlPipe, ClinicrenderComponent, DiagnosisrenderComponent, UnreconciledRendedComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
  ],
  entryComponents: [
    CustomrenderComponent,
    NamerenderComponent,
    MedicationrenderComponent,
    PatientrenderComponent,
    CompliancerenderComponent,
    VitalsrenderComponent,
    ReportsrenderComponent,
    ReportsrenderidComponent,
    ImagerenderComponent,
    ClinicrenderComponent,
    DiagnosisrenderComponent,
    UnreconciledRendedComponent
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
