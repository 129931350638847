import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import swal from 'sweetalert2'

@Component({
  template: `
  <a (click)="onClick()"
  href="javascript:void(0);">{{ renderValue }}</a>
`,

})
export class MedicationrenderComponent implements OnInit {
  public renderValue;

  @Input()
  public value: string;

  @Input()
  rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();


  constructor(private router: Router,) { }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }


  onClick() {
      this.router.navigate([`/medicationlistdetails/${this.rowData.id}`]);
      this.save.emit(this.rowData);
    }


}
