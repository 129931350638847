import { Component, OnInit,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
//import { Cell, DefaultEditor, Editor} from ''
@Component({
  
  template:`
  Name: <input [ngClass]="inputClass"
            #name
            class="form-control short-input"
            [name]="cell.getId()"
            [disabled]="!cell.isEditable()"
            [placeholder]="cell.getTitle()"
            (click)="onClick.emit($event)"
            (keyup)="updateValue()"
            (keydown.enter)="onEdited.emit($event)"
            (keydown.esc)="onStopEditing.emit()"><br>`,
  styleUrls: ['./custom-edit.component.css']
})
export class CustomEditComponent {

  @ViewChild('name') name: ElementRef;

  constructor() { }

  ngAfterViewInit() {
  //  if (this.cell.newValue !== '') {
    //  this.name.nativeElement.value = this.getUrlName();
      //this.url.nativeElement.value = this.getUrlHref();
    }
  }


