import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  currentUser: any;
  constructor(private router: Router,) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //Clear localstorage on expire header
      setTimeout(() => {
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }, 20000000)
      // If Loged in Condition
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!this.currentUser) {
     // this.router.navigate(['/dashboard']);
      this.router.navigate(['/login']);
    }
    //   else {
    //     //this.router.navigate(['/login']);
    //     this.router.navigate(['/dashboard']);
    //  }
  }
}
